
import React, { useEffect, useState } from 'react'
import { Authenticated, UnAuthenticated } from './Router'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { store } from './state/store';
import { login } from './state/userSlice';
import { setIsOnline } from './state/socketSlice';
import { getToken, socket } from './NetworkRequest';
import 'moment/locale/ar'
import { useSocketEvents } from './hooks/useSocketEvents';
function App() {


  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}


const Router = ()=>{

  const user = useSelector(state=>state.user)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  function onConnect() {
    dispatch(setIsOnline(true));
  }

  function onDisconnect() {
    dispatch(setIsOnline(false));
  }

  const events = [
    {
      name: 'connect',
      handler() {
       onConnect()
      },
    },
    {
      name: 'disconnect',
      handler(){
        onDisconnect()
      }
    }
  ];
  useSocketEvents(events);  

  
  useEffect(()=>{
    async function readData() {
      try {
          let jsonUser = await localStorage.getItem('user')
          if(jsonUser) {
              let user = await JSON.parse(jsonUser)
              if(user.refreshToken) {
                  let token = await getToken()
                  if(token) {
                    dispatch(login({
                        name: user.name,
                        token: user.token,
                        refreshToken: user.refreshToken,
                        isAdmin: user.isAdmin
                    }))
                  } 
              }
          }
          
      } catch(e) {
          setLoading(false)
          return
      }
      setLoading(false)
  }

  readData()
  },[])

  if(loading)
    return <div />
  return(
    <React.Fragment>
      {user.token === null ? <UnAuthenticated /> : <Authenticated />}
    </React.Fragment>
  )
}


export default App;
