import React from 'react'
import { useParams, Link } from 'react-router-dom';
import classes from './Patient.module.css'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowLeftAltIcon from '@material-ui/icons/KeyboardBackspace';
import WaitingCard from '../../../../components/WaitingCard';
import { useEffect } from 'react';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { serverConn } from '../../../../NetworkRequest';

const Patient = (props)=>{
    let { mobile } = useParams();
    const [reservations, setReservations] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        const intervalId = setInterval(() => {
            getReservations()
        }, 5000)
        async function getReservations(){
            try{
                let response = await serverConn.post('/reservation/patient', {mobile})
                setReservations(response.data.reservations)
                setLoading(false)
            }catch(e){
                console.log(e)
                setLoading(false)
            }
        }
        getReservations()
        return () => clearInterval(intervalId); 
    },[])



    return(
        <div className={classes.Container}>
          <div className={classes.nav}>
          <div className={classes.navEl}>
              <ArrowLeftAltIcon></ArrowLeftAltIcon>
          <Link to='/'>الرئيسية</Link>
          </div>
          <div className={classes.navEl}>
          <Link to='/register'>الحجز</Link>
          <ArrowRightAltIcon/>
          </div>
        </div>
        <div className={classes.contents}>
            <h2>
                استعلام عن الحجز
            </h2>
         <div className={classes.Form}>
         {loading ? <Box sx={{ display: 'flex' }}>
            <CircularProgress color='success' />
         </Box> :
            reservations.length === 0 ? <Typography fontSize={20} color={'#000'} >عفواً لا يوجد حجوزات</Typography> : reservations.map((r, i)=> 
            <WaitingCard 
                id={r.id}
                name={r.name}
                mobile={r.mobile}
                duration={r.duration}
                visitDate={r.visitDate}
                visitType={r.visitType}
                createdAt={r.createdAt}
                confirmed={r.confirmed}
                index={i}
                waiting={r.waiting}
            />)}
         </div>
        </div>
        </div>
    )
}

export default Patient