import React from 'react'
import { ReactBingmaps } from 'react-bingmaps';
import classes from './SectionThree.module.css';

const SectionThree = (props)=>{

    const callBackMethod = ()=>{
        console.log('click')
    }

    return(
        <section className={classes.map}>
         <ReactBingmaps 
            bingmapKey = "ApYHyEqj7nJK9HFGCRvcH9Ma-fKmD9n0LRPDYc0X0-9RfQg5QuAGsVwTA12HLI8k" 
            center = {[30.3700507,30.5115729]}
            zoom = {15}
            pushPins = {
                [
                  {
                    "location":[30.3700507,30.5115729],
                    "option":{ color: 'red' },
                    "addHandler": {"type" : "click", callback: callBackMethod }
                  }
                ]
              }
         /> 
         </section>

    )
}


export default SectionThree