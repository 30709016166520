import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Button, Divider, FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { authAxios } from '../../NetworkRequest';

const CreateDuration = (props)=>{

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    let { id } = useParams();
    const [durationId, setDurationId] = useState('')
    const [days, setDays] = useState([false,false,false,false,false,false,false])
    const [title, setTitle] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [maxNumber, setMaxNumber] = useState('')
    const { state } = useLocation();

    useEffect(()=>{
        if(id && state !== null){
            setDurationId(id)
            setTitle(state.title)
            setDays(state.days)
            setIsActive(state.isActive)
            setMaxNumber(state.maxNumber+'')
        }
    },[])

    const onChangeDay = (e, d)=>{
        let t_days = days;
        t_days[d] = e.target.checked
        setDays([...t_days])
    }


    async function handleClick() {
        setLoading(true);
        try{
            if(!(+maxNumber===parseInt(maxNumber))){
                setLoading(false)
                return
            }

            if(title.trim().length > 3){
                let result
                if(id && state !== null)
                    result = await authAxios.put('/admin/duration', {
                        id,
                        title,
                        days,
                        isActive,
                        maxNumber
                    })
                else
                    result = await authAxios.post('/admin/duration', {
                        title,
                        days,
                        isActive,
                        maxNumber
                    })
                
                if(result.status === 201 || result.status === 200)
                    navigate('/settings/durations')
            }

            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)

        }
    }

    return(
        <Layout>
            <center>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
            <TextField 
                dir='rtl'
                label="عنوان الفترة" 
                variant="filled" 
                style={{width: '250px', marginBottom: '10px'}}
                onChange={({target})=>setTitle(target.value)}
                value={title}
            />
            <TextField 
                    label="اقصي عدد حجوزات" 
                    variant="outlined" 
                    style={{width: '160px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                    onChange={({target})=>setMaxNumber(target.value)}
                    value={maxNumber}
            />
            <FormGroup>
                <FormControlLabel 
                    control={<Switch checked={isActive} onChange={(e)=>setIsActive(e.target.checked)} />} 
                    label={<Typography style={{ width: 175, textAlign: 'right' }} >تفعيل الفترة</Typography>} 
                />
            </FormGroup>
            </div>
            <Divider  />
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h2>الايام</h2>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <FormGroup>
                    <FormControlLabel 
                        control={<Switch checked={days[6]} onChange={(e)=>onChangeDay(e, 6)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >السبت</Typography>}  />
                    <FormControlLabel 
                        control={<Switch checked={days[0]} onChange={(e)=>onChangeDay(e, 0)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الأحد</Typography>} />
                    <FormControlLabel 
                        control={<Switch checked={days[1]} onChange={(e)=>onChangeDay(e, 1)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الإثنين</Typography>} />
                    <FormControlLabel 
                        control={<Switch checked={days[2]} onChange={(e)=>onChangeDay(e, 2)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الثلاثاء</Typography>}/>
                    <FormControlLabel 
                        control={<Switch checked={days[3]} onChange={(e)=>onChangeDay(e, 3)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الأربعاء</Typography>}/>
                    <FormControlLabel 
                        control={<Switch checked={days[4]} onChange={(e)=>onChangeDay(e, 4)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الخميس</Typography>} />
                    <FormControlLabel 
                        control={<Switch checked={days[5]} onChange={(e)=>onChangeDay(e, 5)}/>} 
                        label={<Typography style={{ width: 175, textAlign: 'right' }} >الجمعة</Typography>} />
                </FormGroup>
               
                <LoadingButton
                        onClick={handleClick}
                        loading={loading}
                        variant="contained" 
                        style={{width: '150px', marginTop: '60px'}}
                >
                    <span>{id && state !== null ? 'تحديث' : 'إضافة'}</span>
                </LoadingButton>
                </div>
            </div>
            </center>
        </Layout>
    )
}


export default CreateDuration