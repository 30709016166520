import classes from './Footer.module.css'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import FacebookIcon from '@material-ui/icons/Facebook'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

const Footer = (props)=>{
    return(
        <footer className={classes.Footer}>
            <div className={classes.Contents}>
              <div className={classes.Icons}>
              <IconPhone />
              <IconFb />
              <IconWhats />
              <IconLocation />
              </div>
              <a href='/login'>تسجيل دخول</a>
              <p>©2024-2025 Zayed Clinic | All Rights Reserved</p>
            </div>
        </footer>
    )
}

const IconPhone = (props)=>(
    <div className={classes.Circle}>
        <a href="tel:01066088063"><PhoneIcon className={classes.icon} /></a>
    </div>
)

const IconFb = (props)=>(
    <div className={classes.Circle}>
        <a href="https://web.facebook.com/ZayedClinic"><FacebookIcon className={classes.icon} /></a>
    </div>
)

const IconWhats = (props)=>(
    <div className={classes.Circle}>
        <a href="https://wa.me/201066088063"><WhatsAppIcon className={classes.icon} /></a>
    </div>
)
const IconLocation = (props)=>(
    <div className={classes.Circle}>
        <a href="https://www.google.com/maps/place/%D8%B2%D8%A7%D9%8A%D8%AF+%D9%83%D9%84%D9%8A%D9%86%D9%8A%D9%83+%D9%84%D8%B5%D8%AD%D8%A9+%D8%A7%D9%84%D8%B7%D9%81%D9%84%25E2%2580%25AD/@30.3701018,30.5115965,15z/data=!4m6!3m5!1s0x1458be155f9d097f:0xedf83c4917780b73!8m2!3d30.3701018!4d30.5115965!16s%252Fg%25"><LocationOnIcon className={classes.icon} /></a>
    </div>
)



export default Footer