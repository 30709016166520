import { useEffect } from 'react';
import { socket } from '../NetworkRequest';


export function useSocketEvents(events) {
  useEffect(() => {
    for (const event of events) {
      socket.on(event.name, event.handler);
    }

    return function () {
      for (const event of events) {
        socket.off(event.name);
      }
    };
  }, []);
}