import React from 'react'
import Home from './Pages/Unauthenticated/Home/Home'
import Register from './Pages/Unauthenticated/Register/Register'
import Status from './Pages/Unauthenticated/Status/Status'
import Patient from './Pages/Unauthenticated/Status/Patient/Patient'
import Login from './Pages/Unauthenticated/Login/Login'
import Footer from './layout/Footer/Footer'
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes
  } from "react-router-dom";
import DashboardHome from './Pages/Authenticated/DashboardHome'
import { createTheme,ThemeProvider } from "@mui/material/styles"  
import Employees from './Pages/Authenticated/Employees'
import Settings from './Pages/Authenticated/Settings'
import Duration from './Pages/Authenticated/Duration'
import CreateDuration from './Pages/Authenticated/CreateDuration'
import CreateReservation from './Pages/Authenticated/CreateReservation'
import WhatsAppEdit from './Pages/Authenticated/WhatsAppEdit'
import ChangePassword from './Pages/Authenticated/ChangePassword'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Almarai',
      'serif',
    ].join(','),
  },
  palette: {
      primary: {
        main: '#7c6252',
      },
      secondary: {
        main: '#000'
      }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: [
          'Almarai',
          'serif',
        ].join(','),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#c5b7b0",
          color: "#000",
        }
      }
    }
  },});




export const UnAuthenticated = (props)=>{


    return(
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/register' Component={Register} />
          <Route path='/status' Component={Status} />
          <Route path='/status/patient/:mobile' Component={Patient} />
          <Route path='/login' Component={Login} />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
        <Footer />
        </BrowserRouter>
        </ThemeProvider>
    )
} 



export const Authenticated = (props)=>{


  return(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <Routes>
        <Route path='/' Component={DashboardHome} />
        <Route path='/new' Component={CreateReservation} />
        <Route path='/employees' Component={Employees} />
        <Route path='/settings' Component={Settings} />
        <Route path='/settings/WhatsAppEdit' Component={WhatsAppEdit} />
        <Route path='/settings/ChangePassword' Component={ChangePassword} />
        <Route path='/settings/durations' Component={Duration} />
        <Route path='/settings/durations/create' Component={CreateDuration} />
        <Route path='/settings/durations/:id' Component={CreateDuration} />
        <Route
            path="*"
            element={<Navigate to="/" />}
        />
      </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
} 

