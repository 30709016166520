import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Alert, AlertTitle, Button, CircularProgress, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { authAxios, serverConn } from '../../NetworkRequest';

const Duration = (props)=>{

    const navigate = useNavigate()
    const [durations, setDurations] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        async function getDurations(){
            try{
                const result = await serverConn.get('/admin/durations')
                setDurations(result.data.durations)
                setLoading(false)
            }catch(e){
                console.log(e)
                setLoading(false)
            }
        }

        getDurations()
    },[])


    const onChangeActiveState = async(e, d)=>{

        try{
            let {id, days, title, isActive, maxNumber} = d
            let result = await authAxios.put('/admin/duration', {
                    id,
                    title,
                    days,
                    isActive: !isActive,
                    maxNumber
            })
            const index = durations.findIndex(el=>el.id === d.id)
            let t_durations = [...durations]
            t_durations[index].isActive = !t_durations[index].isActive
            setDurations(t_durations)
        }catch(e){
            console.log(e)
        }
    }

    return(
        <Layout>
            <center>
            {/* <Alert severity="success" dir='rtl' style={{marginBottom: 12}}>
                <AlertTitle>تم التحديث</AlertTitle>
                تم تحديث الفترة بنجاح
            </Alert> */}
                <Button 
                    onClick={()=>navigate('/settings/durations/create')}
                    style={{width: '250px'}} 
                    variant="outlined">
                        اضافة فترة
                </Button>
                <List sx={{ width: '100%', maxWidth: 660, bgcolor: 'background.paper' }}>
                {loading && <CircularProgress  size={30} style={{marginTop: 40}}/>}
                    {durations.map(r=>(
                        <React.Fragment key={r.id}>
                        <Divider variant="middle" component="li" />
                        <ListItem  key={r.id}>
                            <Switch  onChange={(e)=>onChangeActiveState(e, r)} checked={r.isActive}/>
                            <ListItemButton onClick={()=>navigate(`/settings/durations/${r.id}`, { state: { ...r } })}>
                                <ListItemText style={{alignSelf: 'center', textAlign: 'right'}} primary={r.title} />
                            </ListItemButton>
                        </ListItem>
                        </React.Fragment>
                    ))}
                </List>
               
            </center>
        </Layout>
    )
}


export default Duration