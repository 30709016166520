import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import PatientCard from "../../components/PatientCard/PatientCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../NetworkRequest";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
const ReservationRequest = ({
  reservationRequests,
  setReservationRequests,
  dayReservation,
  setDayReservation,
  durations,
  confirm_msg,
  queue_msg,
  come_msg,
}) => {
  const navigate = useNavigate();

  const onChangeCome = async (id) => {
    try {
      let response = await authAxios.post("/reservation/come", { r_id: id });
      let tmp = [...reservationRequests];
      let foundIndex = tmp.findIndex((x) => x.id === id);
      tmp[foundIndex].come = !reservationRequests[foundIndex].come;
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onChangeUrgent = async (id) => {
    try {
      let response = await authAxios.post("/reservation/urgent", { r_id: id });
      let tmp = [...reservationRequests];
      let foundIndex = tmp.findIndex((x) => x.id === id);
      tmp[foundIndex].veryUrgent = false;
      tmp[foundIndex].urgent = !reservationRequests[foundIndex].urgent;
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeVeryUrgent = async (id) => {
    try {
      let response = await authAxios.post("/reservation/veryurgent", {
        r_id: id,
      });
      let tmp = [...reservationRequests];
      let foundIndex = tmp.findIndex((x) => x.id === id);
      tmp[foundIndex].urgent = false;
      tmp[foundIndex].veryUrgent = !reservationRequests[foundIndex].veryUrgent;
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onDidntCome = async (id) => {
    try {
      let response = await authAxios.post("/reservation/didntcome", {
        r_id: id,
      });
      let tmp = [...reservationRequests];
      let foundIndex = tmp.findIndex((x) => x.id === id);
      //tmp[foundIndex].didntCome = !reservationRequests[foundIndex].didntCome
      tmp.splice(foundIndex, 1);
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onConfirm = async (id) => {
    try {
      let response = await authAxios.post("/reservation/confirm", { r_id: id });
      let tmp = reservationRequests.filter((e) => e.id !== id);
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onCancelConfirm = async (id) => {
    try {
      let tmp = [...reservationRequests];
      let response = await authAxios.post("/reservation/cancel", { r_id: id });
      let foundIndex = tmp.findIndex((x) => x.id === id);
      tmp.splice(foundIndex, 1);
      setReservationRequests(tmp);
    } catch (e) {
      console.log(e);
    }
  };
  const onReject = (index) => {
    let tmp = [...reservationRequests];
    tmp[index].cancel = true;
    setReservationRequests(tmp);
  };
  const onCancelReject = (index) => {
    let tmp = [...reservationRequests];
    tmp[index].cancel = false;
    setReservationRequests(tmp);
  };
  const onUpdateNotes = async (id, note) => {
    let res = await authAxios.post("/reservation/notes", { id, notes: note });
    alert("تم الحفظ");
  };

  const onChangeDayRequest = async (value) => {
    try {
      let response = await authAxios.get(`/reservation/requests?date=${value}`);
      setDayReservation(dayjs(new Date(value)));
      setReservationRequests(response.data.reservations);
    } catch (e) {
      console.log(e);
    }
  };
  const onCancelDateFilter = async () => {
    try {
      let response = await authAxios.get(`/reservation/requests`);
      setDayReservation(null);
      setReservationRequests(response.data.reservations);
    } catch (e) {
      console.log(e);
    }
  };
  const updateReservations = async () => {
    try {
      let results = await authAxios.get(`/reservation/requests`);
      setReservationRequests(results.data.reservations);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <center style={{ marginTop: 20 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {dayReservation !== null && (
            <IconButton onClick={onCancelDateFilter}>
              <CloseIcon />
            </IconButton>
          )}
          <DatePicker
            disablePast
            slotProps={{ textField: { style: { margin: 2 } } }}
            label="اختر تاريخ الحجز"
            format="YYYY - MM - DD"
            value={dayReservation}
            onChange={(value) => onChangeDayRequest(value)}
          />
        </Box>
        <Typography>{reservationRequests.length}</Typography>
      </Box>
      {reservationRequests.map((r, index) => (
        <PatientCard
          key={r.id}
          index={index}
          name={r.name}
          mobile={r.mobile}
          visitDate={r.visitDate}
          visitType={r.visitType}
          number={r.number}
          didntCome={r.didntCome}
          createdBy={r.createdBy}
          duration={r.duration}
          confirmed={r.confirmed}
          done={r.done}
          id={r.id}
          cancel={r.cancel}
          urgent={r.urgent}
          veryUrgent={r.veryUrgent}
          createdAt={r.createdAt}
          come={r.come}
          confirmedBy={r.confirmedBy}
          onChangeCome={onChangeCome}
          onChangeUrgent={onChangeUrgent}
          onChangeVeryUrgent={onChangeVeryUrgent}
          onDidntCome={onDidntCome}
          onConfirm={onConfirm}
          onReject={onReject}
          onCancelConfirm={onCancelConfirm}
          onCancelReject={onCancelReject}
          durations={durations}
          durationId={r.durationId}
          updateReservations={updateReservations}
          showWaiting={r.showWaiting}
          confirm_msg={confirm_msg}
          queue_msg={queue_msg}
          come_msg={come_msg}
          notes={r.notes}
        />
      ))}
    </center>
  );
};

export default ReservationRequest;
