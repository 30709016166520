import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { authAxios } from "../../NetworkRequest";
import { useEffect } from "react";

const ReserveConsultationDialog = ({
  open,
  setOpen,
  name,
  id,
  durations,
  duration,
  visitDate,
  onReserve,
}) => {
  const [toDate, setToDate] = useState(dayjs(visitDate));
  const [toDuration, setToDuration] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDate = async () => {
    try {
      let date = toDate !== null ? toDate : visitDate;
      let days = durations.find(
        (el) => el.id === (toDuration === null ? duration : toDuration)
      ).days;
      if (!days[new Date(date).getDay()]) return;

      let response = await authAxios.post("/reservation/consultation", {
        id,
        durationId: toDuration !== null ? toDuration : duration,
        date: new Date(date).toLocaleDateString(),
      });
      if (response.status === 200) onReserve();
      return setOpen(false);
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
  };

  const onChangeDuration = (value) => {};

  const disabledDates = (s) => {
    let date = new Date(s);
    let days = durations.find(
      (el) => el.id === (toDuration === null ? duration : toDuration)
    ).days;
    return !days[date.getDay()];
  };

  const onChangeDate = (value) => {
    setToDate(dayjs(new Date(value)));
    console.log(new Date(dayjs(new Date(value))).toLocaleDateString());
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ textAlign: "right" }}>حجز استشارة</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "right", marginBottom: 5 }}>
            {name}
          </DialogContentText>
          <FormControl fullWidth sx={{ marginBottom: 4 }}>
            <InputLabel id="select-duration">الفترة</InputLabel>
            <Select
              labelId="select-duration"
              value={toDuration === null ? duration : toDuration}
              //value={durations.filter(d=>d.id === (toDuration === null ? duration : toDuration))[0].id }
              label="durations"
              onChange={({ target }) => setToDuration(target.value)}
            >
              {durations &&
                durations.map((d) => (
                  <MenuItem key={d.id} value={d.id + ""}>
                    {d.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <DatePicker
            shouldDisableDate={disabledDates}
            disablePast
            slotProps={{ textField: { style: { width: "100%" } } }}
            label="اختر تاريخ الحجز"
            format="YYYY - MM - DD"
            value={toDate}
            onChange={onChangeDate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>إلغاء</Button>
          <Button onClick={handleChangeDate}>تأجيل</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ReserveConsultationDialog;
