import React, { useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch } from 'react-redux';
import { logout } from '../state/userSlice';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
   //marginRight: `${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Layout({children}) {
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = useState('الرئيسية')
  const dispatch = useDispatch()

  const handleDrawerOpen = () => {
    setOpen(true);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const onLogout = async()=>{
    await localStorage.clear()
    dispatch(logout())
  }

  useLayoutEffect(() => {
      if(location.pathname.includes('employees'))
        setCurrentPage('ادارة الموظفين')
      else if (location.pathname.includes('settings')){
        if(location.pathname.includes('durations')){
          if(location.pathname.includes('create'))
            setCurrentPage('الإعدادات / الفترات / اضافة فترة')
          else
            setCurrentPage('الإعدادات / الفترات')
        }
        else
          setCurrentPage('الإعدادات')
      }
      
     }, [location])

  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar position="fixed" dir='rtl' open={open} color='primary'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ml: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }} noWrap component="div">
           {currentPage}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <IconButton
              color="inherit"
              aria-label="logout"
              onClick={onLogout}
            >
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        sx={{
         // width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        
           <ListItem key={'الرئيسية'} disablePadding>
              <ListItemButton onClick={()=>navigate('/')}>
                <ListItemIcon>
                 <HomeRoundedIcon />
                </ListItemIcon>
                <ListItemText style={{textAlign: 'right', marginRight: 10}} primary={'الرئيسية'} />
              </ListItemButton>
            </ListItem>
           {/* <ListItem key={'ادارة الموظفين'} disablePadding>
              <ListItemButton onClick={()=>navigate('/employees')}>
                <ListItemIcon>
                 <GroupIcon />
                </ListItemIcon>
                <ListItemText style={{textAlign: 'right', marginRight: 10}} primary={'ادارة الموظفين'} />
              </ListItemButton>
            </ListItem> */}
           <ListItem key={'الإعدادات'} disablePadding>
              <ListItemButton onClick={()=>navigate('/settings')}>
                <ListItemIcon>
                 <TuneIcon />
                </ListItemIcon>
                <ListItemText style={{textAlign: 'right', marginRight: 10}} primary={'الإعدادات'} />
              </ListItemButton>
            </ListItem>
           <ListItem key={'تسجيل خروج'} disablePadding sx={{ display: { sm: 'none', xs: 'block' } }} onClick={onLogout}>
              <ListItemButton onClick={()=>navigate('/')}>
                <ListItemIcon>
                 <LogoutIcon />
                </ListItemIcon>
                <ListItemText style={{textAlign: 'right', marginRight: 10}}  primary={'تسجيل خروج'} />
              </ListItemButton>
            </ListItem>
        </List>
      </Drawer>
      <Main open={open} sx={{padding: '14px'}}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}