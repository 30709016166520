import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import socketSlice from './socketSlice'


export const store = configureStore({
  reducer: {
    user: userReducer,
    socket: socketSlice
  },
})