import classes from '../Unauthenticated/Register/Register.module.css'
import React, {useState, useEffect} from 'react'
import Input  from '../../components/Input/Input'
import { Link } from 'react-router-dom'
import ArrowLeftAltIcon from '@material-ui/icons/KeyboardBackspace';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { authAxios, serverConn } from '../../NetworkRequest'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "dayjs/locale/ar";
import { LoadingButton } from '@mui/lab'



const CreateReservation = (props)=>{
/*     const [selectedDate, handleDateChange] = useState(new Date());
    const [duration, setDuration] = useState(null); */
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [duration, setDuration] = useState(null)
    const [durations, setDurations] = useState([])
    const [date, setDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')
    const [success, setSuccess] = useState(false)

    useEffect(()=>{
      //Get duratons, available dates
      async function getDurations(){
        try{
            const result = await serverConn.get('/reservation/durations')
            setDurations(result.data.durations)
        }catch(e){
            console.log(e)
        }
    }

    getDurations()
    },[])


    const onSelectDuration = ({target})=>{
      const i = durations.findIndex(el=>el.id === target.value)
      setDuration(durations[i])
    }

    const onChangeDate = (value)=>{
      console.log(value.$d)
      if(duration){
        setDate(new Date(value))
      }
      
    }

    const onRegister = async()=>{
      setErr('')
      if(!type || !name || !phone || !date || !duration)
        return
      if(name.trim().length < 3)
        return setErr('من فضلك ادخل الإسم')
      if(phone.trim().length !== 11)
        return setErr('من فضلك ادخل رقم الهاتف')

      try{
        setLoading(true)
        let results = await authAxios.post('/reservation', {
          name,
          mobile: phone,
          visitType: type,
          date,
          durationId: duration.id
        })

        if(results.status === 201)
          setSuccess(true)
        
        setName('')
        setPhone('')
        setDate(null)
      }catch(e){
        console.log(e)
        setErr('من فضلك تأكد من ادخال البيانات بشكل صحيح')
      }
      setLoading(false)
    }

    function disableWeekends(s) {
        let date = new Date(s)
       /*  switch(date.getDay()){
          case 0:
            return duration.days[0]
          case
        } */
        return !duration.days[date.getDay()]
        //return date.getDay() === 0 || date.getDay() === 6;
    }
    return(
      <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale="ar">
        <div className={classes.Container}>
        <div className={classes.nav}>
          <div className={classes.navEl}>
              <ArrowLeftAltIcon />
          <Link to='/'>الرئيسية</Link>
          </div>
        </div>
            <h1>الحجز</h1>
         <div className={classes.Form}>
            <Input
              placeholder='الإسم'
              iconName='user'
              value={name}
              onChange={({target})=>setName(target.value)}
            />
            <Input
              style={{marginTop: 15}}
              placeholder='رقم الهاتف'
              iconName='whatsapp'
              width={180}
              value={phone}
              type="tel"
              onChange={({target})=>setPhone(target.value)}
            />
            <div className={classes.select}>
              <select name="format" id="format" onChange={onSelectDuration}>
                <option selected disabled>اختر الفترة</option>
                {durations.map((d)=><option value={d.id} key={d.id}>{d.title}</option>)}
              </select>
            </div>
            <DatePicker 
              shouldDisableDate={disableWeekends} 
              disablePast 
              slotProps={{ textField: { style: { width: 225 } } }} 
              label="اختر تاريخ الحجز" 
              disabled={duration === null}
              format="YYYY - MM - DD"
              value={date}
              onChange={onChangeDate}
            />
            <FormControl component="fieldset" className={classes.radio}>
            <FormLabel>نوع الزيارة</FormLabel>
            <RadioGroup aria-label="reason" name="reason"  onChange={(e)=>setType(e.target.value)}>
                <FormControlLabel labelPlacement="start" value="Revelation" control={<Radio />} label="كشف" />
                <FormControlLabel labelPlacement="start" value="Consultation" control={<Radio />} label="استشارة" />
            </RadioGroup>
            </FormControl>
              {err && <p style={{margin: 0, padding: 0, color: 'red', fontSize: 14}}>{err}</p>}
              {success && <p style={{margin: 0, padding: 0, color: 'green', fontSize: 14}}>{'تم الحجز بنجاح'}</p>}
            <LoadingButton
                        onClick={onRegister}
                        loading={loading}
                        variant="contained" 
                        style={{width: '140px', marginTop: '15px'}}
                >
                    <span>إنشاء حجز</span>
            </LoadingButton>
         </div>
        </div>
        </LocalizationProvider>
    )
}

export default CreateReservation