import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Button, Divider, FormControlLabel, FormGroup, Input, Switch, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { authAxios, serverConn } from '../../NetworkRequest';

const ChangePassword = (props)=>{

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [password, setpassword] = useState('')
    const [new_pass, setNewPass] = useState('')
    const [new_pass_confirm, setNewPass_confirm] = useState('')

    useEffect(()=>{
    
    },[])


    async function handleClick() {
        setLoading(true);
        try{
            let response = await authAxios.post('/admin/settings/whatsapp', {
                password,
                new_pass,
                new_pass_confirm
            })
            setSuccess(true)
            setTimeout(()=>{
                setSuccess(false)
            }, 2000)
            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)

        }
    }

    return(
        <Layout>
            <center>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', width: '100%'}}>
            <h3 style={{textAlign: 'center', marginTop: 20}}>تغيير كلمة المرور</h3>
            <TextField 
                multiline
                label="كلمة المرور الحالية" 
                variant="outlined" 
                style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                onChange={({target})=>setpassword(target.value)}
                value={password}
                fullWidth
                type='password'
            />
            <TextField 
                multiline
                label="كلمة المرور الجديدة" 
                style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                onChange={({target})=>setNewPass(target.value)}
                value={new_pass}
                fullWidth
                inputMode='text'
                type='password'
                security='password'
            />
            <Input 
                type='password' 
                multiline
                label="تأكيد كلمة المرور الجديدة" 
                variant="outlined" 
                style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                onChange={({target})=>setNewPass_confirm(target.value)}
                value={new_pass_confirm}
                fullWidth
                componentsProps={{input: {type: 'password'}}}
            />
            <TextField 
                    multiline
                    label="تأكيد كلمة المرور الجديدة" 
                    variant="outlined" 
                    style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                    onChange={({target})=>setNewPass_confirm(target.value)}
                    value={new_pass_confirm}
                    fullWidth
                    type='password'
            />
            </div>
                {success && <p style={{color: 'green'}}>تم الحفظ</p>}
                {error && <p style={{color: 'red'}}>عفواً حدث خطأ</p>}

                <LoadingButton
                        onClick={handleClick}
                        loading={loading}
                        variant="contained" 
                        style={{width: '150px', marginTop: '20px'}}
                >
                    <span>حفظ</span>
                </LoadingButton>
            </center>
        </Layout>
    )
}


export default ChangePassword