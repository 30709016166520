import { createSlice } from "@reduxjs/toolkit"
import { socket } from "../NetworkRequest"

const initialState = {
    isOnline: false
  }
  
  export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setIsOnline: (state, action) => {
            state.isOnline = action.payload
        },
    }
  })
  
  // Action creators are generated for each case reducer function
  export const { setIsOnline } = socketSlice.actions
  export default socketSlice.reducer