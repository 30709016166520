import React, { useState } from 'react'
import classes from './Login.module.css'
import ArrowLeftAltIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom'
import PersonIcon from '@material-ui/icons/Person'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import { useDispatch } from 'react-redux';
import { login } from '../../../state/userSlice';
import { serverConn } from '../../../NetworkRequest';

const Login = (props)=>{

    const dispatch = useDispatch()
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')


    const onClickLogin = async ()=>{
      setLoading(true)
      if(username && password){
        try{
        let result = await serverConn.post('/login', {mobile: username.trim(), password})
        await localStorage.setItem('user', JSON.stringify(result.data))
        console.log(result.data)
        dispatch(login({
          name: result.data.name, 
          token: result.data.token, 
          refreshToken: result.data.refreshToken, 
          isAdmin: result.data.isAdmin
        }))

        }catch(e){
          setLoading(false)
          if(e.response.data){
            console.log(e.response.data)
            setErr(e.response.data.msg)
          }
        }
      }
      setLoading(false)
    }

    return(
        <div className={classes.Container}>
          <div className={classes.nav}>
          <div className={classes.navEl}>
              <ArrowLeftAltIcon></ArrowLeftAltIcon>
          <Link to='/'>الرئيسية</Link>
          </div>
          </div>
         <div className={classes.contents}>
         <div className={classes.user}>
         <PersonIcon className={classes.userIcon} style={{fontSize: 40}}/>
         </div>
         <div className={classes.form}>
           <Input 
            iconName='user' 
            placeholder='اسم المستخدم' 
            onChange={({target})=>setUserName(target.value)} 
            />
           <Input 
            iconName='lock' 
            placeholder='كلمة المرور' 
            type='password' 
            onChange={({target})=>setPassword(target.value)} 
            />
            
           <Button 
            style={{marginTop: 10}}
            outline 
            width={150} 
            height={42} 
            onClick={onClickLogin} 
            disabled={loading} 
            loading={loading}>
              تسجيل دخول
            </Button>
            

         </div>
         {err && <p style={{padding: 0, margin: 0, fontSize: 16, color: 'darkred', marginTop: 15}}>{err}</p>}
         </div>
        </div>
    )
}

export default Login