import React, { useState } from 'react'
import { Card, CardContent, Typography } from '@mui/material';
import { Box } from '@material-ui/core';
import InsertInvitationTwoToneIcon from '@mui/icons-material/InsertInvitationTwoTone';


import moment from 'moment/moment';


const WaitingCard = ({
    id,
    name,
    mobile,
    duration,
    visitDate,
    visitType,
    createdAt,
    confirmed,
    index,
    waiting,
  })=>{
  
  
  
    return (
      <Card sx={{ minWidth: 275, maxWidth: 600, marginBottom: 2 }} >
    
      <CardContent>
       <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
       <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row',  }}>
        
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row',  }}>
          <Box>
            <Typography sx={{ flex: 1, fontSize: 14, textAlign: 'right', marginTop: 0.4 }} color="text.secondary" gutterBottom>
            {moment(new Date(visitDate)).locale('en').format('YYYY-MM-DD')} / {moment(new Date(visitDate)).locale('ar').format('dddd')}
            </Typography>
            <Typography sx={{ flex: 1, fontSize: 14, textAlign: 'center'}} color="text.secondary" gutterBottom>
              {duration}
            </Typography>
            </Box>
            <InsertInvitationTwoToneIcon style={{marginLeft: 10}} />
        </Box>
      </Box>
        <Box sx={{display: 'flex', justifyItems: 'center', justifyContent: 'center', marginTop: 2}}>
          <Typography sx={{ textAlign: 'center', color: '#000', marginLeft: 2 }} variant="h5" component="div">
          {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'}}>
          <Typography sx={{ textAlign: 'center', marginTop: 1}}>
            {mobile}
          </Typography>
        </Box>
        <Typography sx={{ textAlign: 'center'}} color="text">
          {visitType}
        </Typography>
        {!confirmed ? <Typography sx={{ textAlign: 'center' }}>
          في انتظار تأكيد الحجز
        </Typography> : <Typography sx={{ textAlign: 'center' }}>
          المنتظرين: {waiting}
        </Typography>}
      </CardContent>
      <Typography sx={{ textAlign: 'center', fontSize: 12 }}  color="text.secondary" gutterBottom>
       {moment(new Date(createdAt)).locale('en').format('YYYY-MM-DD')} / {moment(new Date(createdAt)).locale('en').format('h:mm A')} 
        </Typography>
  </Card>
    )
  }
  
export default WaitingCard