import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography } from '@mui/material';


const RejectReservationDialog = ({open, setOpen, name, id, index, onConfirm})=>{

    const handleClose = () => {
      setOpen(false);
    };

    const onPressYes = ()=>{
        onConfirm(id)
        setOpen(false)
        return
    }

    return(
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} style={{minWidth: '60%'}}>
        <DialogTitle sx={{textAlign: 'right'}}>إلغاء الحجز</DialogTitle>
        <DialogContent sx={{minWidth: '60%'}}>
          <Typography sx={{textAlign: 'right', margin: '0 0 0 60px'}}>
          هل انت موافق علي إلغاء الحجز ل <br/> ؟{name}
          </Typography>
        </DialogContent>
        <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Button onClick={handleClose}>لا</Button>
          <Button onClick={onPressYes}>نعم</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    )
}

export default RejectReservationDialog