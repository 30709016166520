import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import styled from 'styled-components'

/* Props
   -outline
   -primary
   -width,height
   -fontSize
   -to
   -onClick

*/

const Btn =  styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props=> props.width ? props.width : 170}px;
    height: ${props=> props.height ? props.height : 43}px;
    ${props=> props.outline ? '' :  `background-color: ${props.primary ? '#7C6252' : '#C5B7B0'};`}
    color:  ${props=> props.primary ? '#F4DFCA' : '#6D4C37'};
    ${props=> props.outline ? 'border: 1px solid #7C6252;' : ''}
    border-radius: 18px;
    text-decoration: none;
    margin: 1px;
    transition: background-color 0.2s ease, color 0.2s ease;
    &:hover{
        background-color: #a1755a;
        color: #F4DFCA;
    }
    pointer-events: ${props=> props.disabled ? 'none' : 'auto'}
`

const Text = styled.p`
font-size: ${props=> props.fontSize ? props=> props.fontSize+'px;' : ''}
`


const Button = (props)=>{
  
   return(
    <div>
      <Btn {...props}>
        {props.loading ?  <CircularProgress  size={24}/> : <Text fontSize={props.fontSize}>{props.children}</Text>}
      </Btn>
    </div>
   )
}

export default Button