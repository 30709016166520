import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, MenuItem, FormControl, InputLabel, Select, TextField, Typography } from '@mui/material';
import { authAxios } from '../../NetworkRequest';

const EditReservationDataDialog = ({
  id, 
  open, 
  setOpen, 
  name, 
  mobile,
  visitType,
  onSaveReservation
})=>{
    const [name_n, setName] = useState(name)
    const [mobile_n, setMobile] = useState(mobile)
    const [visitType_n, setVisitType] = useState(visitType)
    const [err, setErr] = useState('')

    const handleClose = () => {
      setOpen(false);
    };

    const onChangeName = ({target})=>{
        setErr('')
        setName(target.value)
    }
    const onChangeMobile = ({target})=>{
        setErr('')
        setMobile(target.value)
    }

    const onClickSave = async()=>{
      try{
        let request = await authAxios.post('reservation/edit', {
            id,
            name: name_n,
            mobile: mobile_n,
            visitType: visitType_n === 'كشف' ? 'Revelation' : 'Consultation'
        })
        if(request.status === 200){
            onSaveReservation()
            handleClose()
        }
      }catch(e){
    
        if(e.response && e.response.data)
            setErr(e.response.data.errorMessage)
        console.log(e)
      }
      //setOpen(false);
    }


    return(
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{textAlign: 'right'}}>تعديل بيانات الحجز</DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}>
          <DialogContentText sx={{textAlign: 'right', marginBottom: 5}}>
           
          </DialogContentText>
    
            <TextField 
                value={name_n}
                onChange={onChangeName}
                label='الإسم'
                variant='filled'
                sx={{marginBottom: 2}}
                fullWidth
            />
            <TextField 
                value={mobile_n}
                onChange={onChangeMobile}
                sx={{marginBottom: 2}}
                label='رقم الهاتف'
                variant='filled'
                fullWidth
            />
          <FormControl fullWidth sx={{marginBottom: 2 }}>
            <InputLabel id="select-duration">نوع الزيارة</InputLabel>
            <Select
              labelId="select-duration"
              value={visitType_n}
              label="durations"
              onChange={({target})=>setVisitType(target.value)}
            >
              <MenuItem  value={'كشف'}>{'كشف'}</MenuItem>
              <MenuItem  value={'استشارة'}>{'استشارة'}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <Typography color={'red'} align='center'>
            {err}
        </Typography>
        <DialogActions sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '5px 12px'}}>
          <Button onClick={handleClose} variant='outlined'>إلغاء</Button>
          <Button onClick={onClickSave} variant='outlined'>حفظ</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    )
}

export default EditReservationDataDialog