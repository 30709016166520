import React from 'react'
import Layout from '../../layout/Layout'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import StarIcon from '@mui/icons-material/Star';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PasswordIcon from '@mui/icons-material/Password';
import WHIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom';

const Settings = (props)=>{
    const navigate = useNavigate()

    return(
        <Layout>
            <center>
            <List sx={{ width: '100%', maxWidth: 660, bgcolor: 'background.paper' }}>
                <ListItem onClick={()=>navigate('/settings/durations')}>
                <ListItemButton>
                <ListItemIcon>
                    <DateRangeIcon />
                </ListItemIcon>
                <ListItemText style={{alignSelf: 'center', textAlign: 'right'}} primary="الفترات" />
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
                <ListItem onClick={()=>navigate('/settings/WhatsAppEdit')}> 
                <ListItemButton>
                <ListItemIcon>
                    <WHIcon />
                </ListItemIcon>
                <ListItemText 
                    style={{alignSelf: 'center', textAlign: 'right'}} 
                    primary="رسائل واتس آب" 
                />
                </ListItemButton>
            </ListItem>
            <Divider variant="middle" component="li" />
                <ListItem onClick={()=>navigate('/settings/ChangePassword')}>
                <ListItemButton>
                <ListItemIcon>
                    <PasswordIcon />
                </ListItemIcon>
                <ListItemText 
                    style={{alignSelf: 'center', textAlign: 'right'}} 
                    primary="تغيير كلمة المرور" 
                />
                </ListItemButton>
            </ListItem>
            </List>
            </center>
        </Layout>
    )
}


export default Settings