import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Button, Divider, FormControlLabel, FormGroup, Switch, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { authAxios, serverConn } from '../../NetworkRequest';

const WhatsAppEdit = (props)=>{

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [confirm_msg, setConfirm_msg] = useState('')
    const [queue_msg, setQueue_msg] = useState('')
    const [come_msg, setCome_msg] = useState('')

    useEffect(()=>{
        async function getWhMessages(){
            try{
                let response = await serverConn.get('/admin/settings/whatsapp')
                setConfirm_msg(response.data.confirm_msg)
                setQueue_msg(response.data.queue_msg)
                setCome_msg(response.data.come_msg)
            }catch(e){
                console.log(e)
            }
        }
        getWhMessages()
    },[])


    async function handleClick() {
        setLoading(true);
        try{
            let response = await authAxios.post('/admin/settings/whatsapp', {
                confirm_msg,
                queue_msg,
                come_msg
            })
            setSuccess(true)
            setTimeout(()=>{
                setSuccess(false)
            }, 2000)
            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)

        }
    }


/* 

*/


    return(
        <Layout>
            <center>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', width: '100%'}}>
            <h3 style={{textAlign: 'center', marginTop: 20}}>رسائل واتس آب</h3>
            <TextField 
                    multiline
                    label="رسالة تأكيد الحجز" 
                    variant="outlined" 
                    style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                    onChange={({target})=>setConfirm_msg(target.value)}
                    value={confirm_msg}
                    fullWidth
                    color='success'
                   /*  inputProps={{
                        sx: {
                          textAlign: "right",
                          "&::placeholder": {
                            textAlign: "right",
                          },
                        },
                      }} */
                    inputProps={{style: { textAlign: 'right' }}}
            />
            <TextField 
                    multiline
                    label="رسالة الدور" 
                    variant="outlined" 
                    style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                    onChange={({target})=>setQueue_msg(target.value)}
                    value={queue_msg}
                    fullWidth
                    color='info'
                    inputProps={{style: { textAlign: 'right' }}}

            />
            <TextField 
                    multiline
                    label="رسالة الإستدعاء" 
                    variant="outlined" 
                    style={{maxWidth: '400px', marginBottom: '10px', marginTop: 15, marginBottom: 15}}
                    onChange={({target})=>setCome_msg(target.value)}
                    value={come_msg}
                    fullWidth
                    color='warning'
                    inputProps={{style: { textAlign: 'right' }}}

            />
            </div>
                {success && <p style={{color: 'green'}}>تم الحفظ</p>}
                {error && <p style={{color: 'red'}}>عفواً حدث خطأ</p>}

                <LoadingButton
                        onClick={handleClick}
                        loading={loading}
                        variant="contained" 
                        style={{width: '150px', marginTop: '20px'}}
                >
                    <span>حفظ</span>
                </LoadingButton>
            </center>
        </Layout>
    )
}


export default WhatsAppEdit