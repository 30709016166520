import React from 'react'
import classes from './Status.module.css'
import { Link, useNavigate } from 'react-router-dom'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowLeftAltIcon from '@material-ui/icons/KeyboardBackspace';
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import { useState } from 'react';

const Status = (props)=>{
    const navigate = useNavigate()
    const [mobile, setMobile]= useState();


    const onClickNext = ()=>{
        if(mobile.trim().length === 11)
        navigate(`/status/patient/${mobile}`)
    }

    return(
        <div className={classes.Container}>
          <div className={classes.nav}>
          <div className={classes.navEl}>
              <ArrowLeftAltIcon></ArrowLeftAltIcon>
          <Link to='/'>الرئيسية</Link>
          </div>
          <div className={classes.navEl}>
          <Link to='/register'>الحجز</Link>
          <ArrowRightAltIcon/>
          </div>
        </div>
        <div className={classes.contents}>
         <div className={classes.Form}>
         <Input placeholder='رقم الهاتف'  iconName='whatsapp'  value={mobile} onChange={({target})=>setMobile(target.value)}/>
         <Button onClick={onClickNext} style={{marginTop: 60}}>ابحث</Button>
         </div>
        </div>
        </div>
    )
}

export default Status