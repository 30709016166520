import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  name: null,
  token: null,
  refreshToken: null,
  isAdmin: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.name = action.payload.name
      state.token = action.payload.token
      state.refresh = action.payload.refresh
      state.isAdmin = action.payload.isAdmin
    },
    logout: (state) => {
      return initialState
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer