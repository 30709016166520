import React from 'react'
import classes from './SectionOne.module.css'
import Button from '../../../../components/Button/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery'
const SectionOne = (props)=>{
    const matches = useMediaQuery('(max-width:890px)')

    return(
        <section className={classes.SectionOne}>
            <div className={classes.Darker} />
            <div className={classes.BackgroundImage}/> 
            <div className={classes.SiteInfo}>
                <p className={classes.SiteName}>زايد كلينك</p>
                <p className={classes.SmallText}>لصحة طفلك</p>
                <p className={classes.InfoText}>يمكنك الحجز من خلال موقعنا الإلكتروني وسوف يتم تأكيد حجزك والتواصل معك من قبل العاملين بالعيادة</p>
                
                <div className={classes.Btns}>
                    <Button className={classes.Btn} 
                            style={matches ? {marginTop: 15, marginRight: 13}:{marginRight: 13}}
                            width={matches ? 230 : null}
                            primary href='/register' 
                            >
                                احجز الآن
                    </Button>
                    <Button className={classes.Btn} 
                            style={matches ? {marginTop: 15}:{}}
                            width={matches ? 230 : null}
                            href='/status'
                            outline={matches ? false : true}
                            >
                            استعلام
                    </Button>
                </div>
                
            </div>
        </section>
    )
}

export default SectionOne