import React from 'react'
import SectionOne from './SectionOne/SectionOne'
import SectionTwo from './SectionTwo/SectionTwo'
import SectionThree from './SectionThree/SectionThree'
const Home = (props)=>{


    return(
        <div>
             <SectionOne />
             {/* <SectionTwo /> */}
             <SectionThree />
        </div>
    )
}

export default Home