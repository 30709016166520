import React from 'react'
import styled from 'styled-components'
import Icon from 'react-web-vector-icons'


/* 
   /* Props
   -Input defult props
   -style on div
   -iconName
   -err
   -errorMsg
   -width, height
*/


const TextArea = styled.textarea`
    width: ${props=> props.width ? props.width : 194}px;
    height: ${props=> props.height ? props.height : 90}px;
    padding: 10px;
    background-color: #E9D3BD;
    text-align: right;
    border: 2px solid #E9D3BD;
    font-family: 'Almarai';
    font-size: 15px;
    resize: none;
    border-radius: 5px;
    transition: border 0.2s ease;
    &:hover{
        border-color: #7C6252;
    }
    &:focus{
        border-color: #7C6252;
    }
    
`

const Inp = styled.input`
  width: ${props=> props.width ? props.width : 180}px;
  height: ${props=> props.height ? props.height : 15}px;
  padding: 10px 4px;
  background-color: #E9D3BD;
  border: 0;
  font-family: 'Almarai';
  font-size: 15px;
  text-align: center;
  outline: none;
  *:focus {
    outline: none;
  }
` 
const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E9D3BD;
    padding: 2px;
    transition: border 0.2s ease;
    border: 2px solid ${props=>props.err ? 'red' :  '#E9D3BD'};
    padding-right: 9px;
    border-radius: 5px;
    &:hover{
        border-color: #7C6252;
    }
    &:focus{
      border-color: #7C6252;
    }
    &:active{
        border-color: #7C6252;
      }
`
const Container = styled.div`
 display: flex;
 justify-content: center;
 align-items: 'center';
 flex-direction: column;
 
`
const ErrorText = styled.span`
 width: ${props=> props.width ? props.width+35 : 214}px;
 text-align: right;
 color: red;
 margin: 4px;
 font-size: 14px;
`

const Input = (props)=>{
    
    return(
        <>
        {props.text ?
        <Container>
          <TextArea {...props}/>
          {props.errorMsg ? 
          <ErrorText width={props.width}>
             {props.errorMsg} 
          </ErrorText> 
          : 
          null}
        </Container>
         : 
        <Container >
        <Div {...props}>
          <Inp 
              onChange={props.onChange} 
              placeholder={props.placeholder} 
              width={props.width} 
              height={props.height}
              value={props.value}
              type={props.type}
              disabled={props.disabled}
          /> 
          {props.iconName ?
            <Icon
            name={`${props.iconName}`}
            font='FontAwesome'
            color='#7C6252'
            size={20}
            />
        :
         null
         }
        </Div>
          {props.errorMsg ? 
          <ErrorText width={props.width}>
             {props.errorMsg} 
          </ErrorText> 
          : 
          null}
        </Container>}
        </>

    )
}



export default Input